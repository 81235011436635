import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
// import Browse from './views/Browse.vue';
// import ExerciseList from './views/ExerciseList.vue';
// import ExerciseDetail from './views/ExerciseDetail.vue';
// import WorkoutCreate from './views/WorkoutCreate.vue';
// import WorkoutList from './views/WorkoutList.vue';
// import WorkoutDetail from './views/WorkoutDetail.vue';
// import ProgramCreate from './views/ProgramCreate.vue';
// import ProgramDetail from './views/ProgramDetail.vue';
// import ProgramList from './views/ProgramList.vue';
// import ResetPassword from './views/Reset-Password.vue';
// import Signup from './views/Signup.vue';
// import Login from './views/Login.vue';
// import ForgotPassword from './views/ForgotPassword.vue';
// import Settings from './views/Settings.vue';
// import NewsletterView from './views/NewsletterView.vue';
import {RootState} from "@/store/store";
import {Store} from 'vuex';
import Meta from "vue-meta";
// import {makeAnonGuard} from "@/guards/user";
// import {
//     KIND_COOLDOWN,
//     KIND_PREPARE,
//     KIND_REST,
//     KIND_WORK,
//     LEVEL_KIND_ADVANCED,
//     LEVEL_KIND_SIMPLE
// } from "@/models/constants";

Vue.use(Router);
Vue.use(Meta);

export default function makeRouter(store: Store<RootState>): Router {
    // const isAnonGuard = makeAnonGuard(store);
    return new Router({
        mode: 'history',
        routes: [
            {
                path: '/',
                name: 'home',
                component: Home,
            },
            // {
            //     path: '/me',
            //     name: 'settings',
            //     component: Settings,
            // },
            // {
            //     path: '/quickstart',
            //     name: 'quickstart',
            //     component: () => import(/* webpackChunkName: "quickstart", webpackPrefetch: 50 */ './views/Quickstart.vue'),
            //     props: (route) => {
            //         if (route.query.d) {
            //             // turns url into a preset-object
            //             const kind = route.query.k === "a" ? LEVEL_KIND_ADVANCED : LEVEL_KIND_SIMPLE;
            //             const unit = route.query.u === "r" ? "repetitions" : "seconds";
            //             const auto = route.query.a == "1" || false;
            //             const rawData = (<string>route.query.d).split("-");
            //             if (kind === LEVEL_KIND_ADVANCED) {
            //                 const sets: any[] = [];
            //                 sets.push({kind: KIND_PREPARE, value: parseInt(rawData[0])});
            //                 const a = rawData.slice(1, -1);
            //                 for (let i = 0; i < a.length; i++) {
            //                     const str = a[i];
            //                     switch (i%2) {
            //                         case 0:
            //                             const w = a[i].split("~");
            //                             const value = parseInt(w[0]);
            //                             const step = unit === "repetitions" && w.length > 1 ? Math.round((parseFloat(w[1]) * 1000) / 100) * 100 : 1000;
            //                             sets.push({kind: KIND_WORK, value: value, step: step});
            //                             break;
            //                         case 1:
            //                             sets.push({kind: KIND_REST, value: parseInt(str)});
            //                             break;
            //                     };
            //                 }
            //                 sets.push({kind: KIND_COOLDOWN, value: parseInt(rawData[rawData.length - 1])});
            //                 return {
            //                     params: {
            //                         preset: {
            //                             primary_unit: unit,
            //                             row: {
            //                                 kind: kind,
            //                                 data: {sets: sets}
            //                             }
            //                         },
            //                         auto: auto,
            //                     }
            //                 }
            //             } else {
            //                 // SIMPLE
            //                 const w = rawData[2].split("~");
            //                 const data: any = {
            //                     prepare: parseInt(rawData[0], 10),
            //                     repeat: parseInt(rawData[1], 10),
            //                     work: parseInt(w[0], 10),
            //                     step: w.length > 1 ? Math.round((parseFloat(w[1]) * 1000) / 100) * 100 : 1000,
            //                     rest: parseInt(rawData[3], 10),
            //                     cooldown: parseInt(rawData[4], 10)
            //                 };
            //                 return {
            //                     params: {
            //                         preset: {
            //                             primary_unit: unit,
            //                             row: {
            //                                 kind: kind,
            //                                 data: data
            //                             }
            //                         },
            //                         auto: auto,
            //                     }
            //                 }
            //             }
            //         }
            //         return {
            //             params: null
            //         };
            //     }
            // },
            // {
            //     path: '/exercises',
            //     // alias: ['/workouts', '/programs'],
            //     component: Browse,
            //     children: [
            //         {
            //             path: '/exercises/p/:pos?',
            //             name: 'exercise-list',
            //             alias: ['/exercises'],
            //             component: ExerciseList,
            //             props: (route) => ({
            //                 pos: route.params.pos || null
            //             })
            //         },
            //         // {
            //         //     path: '/workouts/p/:pos?',
            //         //     name: 'workout-list',
            //         //     alias: ['/workouts'],
            //         //     component: WorkoutList,
            //         //     props: (route) => ({
            //         //         pos: route.params.pos || null
            //         //     })
            //         // },
            //         // {
            //         //     path: '/programs/p/:pos?',
            //         //     name: 'program-list',
            //         //     alias: ['/programs'],
            //         //     component: ProgramList,
            //         //     props: (route) => ({
            //         //         pos: route.params.pos || null
            //         //     })
            //         // },
            //         {
            //             path: '',
            //             redirect: '/exercises'
            //         },
            //     ],
            // },
            // {
            //     path: '/exercises/create',
            //     name: 'exercise-create',
            //     component: () => import(/* webpackChunkName: "exercise-create", webpackPrefetch: 100 */ './views/ExerciseCreate.vue'),
            // },
            // {
            //     path: '/exercises/uuid/:uuid/edit',
            //     name: 'exercise-edit',
            //     component: () => import(/* webpackChunkName: "exercise-create", webpackPrefetch: 100 */ './views/ExerciseCreate.vue'),
            //     props: true
            // },
            // {
            //     path: '/exercises/uuid/:uuid',
            //     name: 'exercise-detail-uuid',
            //     component: ExerciseDetail,
            //     props: true
            // },
            // {
            //     path: '/exercises/:slug',
            //     name: 'exercise-detail',
            //     component: ExerciseDetail,
            //     props: true
            // },
            // {
            //     path: '/workouts/create',
            //     name: 'workout-create',
            //     component: WorkoutCreate,
            // },
            // {
            //     path: '/workouts/:slug',
            //     name: 'workout-detail',
            //     component: WorkoutDetail,
            //     props: true
            // },
            // {
            //     path: '/programs/create',
            //     name: 'program-create',
            //     component: ProgramCreate,
            // },
            // {
            //     path: '/programs/:slug',
            //     name: 'program',
            //     component: ProgramDetail,
            //     props: true
            // },
            // {
            //     path: '/reset-password',
            //     name: 'reset-password',
            //     component: ResetPassword
            // },
            // {
            //     path: '/signup',
            //     name: 'signup',
            //     component: Signup,
            //     // beforeEnter: isAnonGuard
            // },
            // {
            //     path: '/login',
            //     name: 'login',
            //     component: Login,
            //     // beforeEnter: isAnonGuard
            // },
            // {
            //     path: '/forgot-password',
            //     name: 'forgot-password',
            //     component: ForgotPassword
            // },
            // {
            //     path: '/newsletter',
            //     name: 'newsletter',
            //     component: NewsletterView
            // },
            {
                path: '/accountdeletion',
                name: 'account-deletion',
                component: () => import(/* webpackChunkName: "accountdeletion", webpackMode: "lazy", webpackPrefetch: -998 */ './views/Account-Deletion.vue')
            },
            {
                path: '/privacy',
                name: 'privacy-policy',
                component: () => import(/* webpackChunkName: "privacy", webpackMode: "lazy", webpackPrefetch: -998 */ './views/Privacy.vue')
            },
            {
                path: '/terms',
                name: 'terms-and-conditions',
                component: () => import(/* webpackChunkName: "terms", webpackMode: "lazy", webpackPrefetch: -999 */ './views/Terms.vue')
            },
        ],
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition
            } else {
                return {x: 0, y: 0}
            }
        }
    });
}
