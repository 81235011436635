export default function(timeSec: number): string {
    const nowSec = new Date().getTime() / 1000;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todaySec = today.getTime() / 1000;
    const tomorrow = new Date();
    tomorrow.setHours(24, 0, 0, 0);
    const tomorrowSec = tomorrow.getTime() / 1000;
    if (timeSec >= todaySec && timeSec < tomorrowSec) {
        return "TODAY\xa0";
    } else if (timeSec >= tomorrowSec && timeSec < (tomorrowSec + 86400)) {
        return "TOMORROW\xa0";
    } else {
        return "";
    }
}