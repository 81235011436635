"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function getStatefulPromise() {
    let res;
    let rej;
    const p = new Promise((resolve, reject) => {
        res = resolve;
        rej = reject;
    });
    p.resolve = res;
    p.reject = rej;
    p.resolved = false;
    p.rejected = false;
    p.isResolved = () => {
        return p.resolved;
    };
    p.isRejected = () => {
        return p.resolved;
    };
    p.isFulfilled = () => {
        return p.resolved || p.rejected;
    };
    p.then(() => {
        p.resolved = true;
    }).catch(err => {
        p.rejected = true;
        throw err;
    });
    return p;
}
exports.getStatefulPromise = getStatefulPromise;
