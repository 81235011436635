"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class FormattedNumber {
    constructor(value) {
        this._value = 0;
        this._formatted = 0;
        this.value = value;
    }
    get value() {
        return this._value;
    }
    set value(val) {
        this._value = val;
        this._formatted = (this._value / 1000);
    }
    get formatted() {
        return this._formatted;
    }
}
exports.FormattedNumber = FormattedNumber;
