import Vue from 'vue';
import App from './App.vue';
import makeRouter from './router';
import makeStore, {RootState} from './store/store';
import makeHttpClient from "@/services/httpClient/httpClient";
import DurationFilter from "@/filters/duration";
import DateFilter from "@/filters/date";
import RelativeDateFilter from "@/filters/relative-date";
import MillisSeconds from "@/filters/millis-seconds";
import * as config from './config';
import {sync} from 'vuex-router-sync';

Vue.config.productionTip = false;
Vue.filter('duration', DurationFilter);
Vue.filter('millis', MillisSeconds);
Vue.filter('date', DateFilter);
Vue.filter('reldate', RelativeDateFilter);

export function createApp() {
    const httpClient = makeHttpClient(config.BASE_API);
    const store = makeStore(httpClient);
    const router = makeRouter(store);

    // sync so that route state is available as part of the store
    sync(store, router);

    const app = new Vue({
        router,
        store,
        httpClient,
        render: (h) => h(App),
    });
    return {app, router, store};
}
