import Vue from 'vue';
import Vuex, {Store} from 'vuex';
import makeUserModule from "@/store/modules/user";
import {AxiosInstance} from "axios";
// import makeNewsletterModule from "@/store/modules/newsletter";
// import makeTrainingModule from "@/store/modules/training";
import makeExerciseModule from "@/store/modules/exercises";

const ENABLE_LOGGING = false;

Vue.use(Vuex);

export interface RootState {
}

export default function makeStore(httpClient: AxiosInstance): Store<RootState> {
    const userModule = makeUserModule(httpClient);
    // const newsletterModule = makeNewsletterModule(httpClient);
    // const trainingModule = makeTrainingModule(httpClient);
    const exerciseModule = makeExerciseModule(httpClient);
    const store = new Vuex.Store<RootState>({
        state: {},
        mutations: {},
        actions: {},
        modules: {
            user: userModule,
            // newsletter: newsletterModule,
            // training: trainingModule,
            exercise: exerciseModule
        }
    });
    store.watch((state: RootState) => {
            return store.getters['user/user'];
        }, function (value: any) {
            if (ENABLE_LOGGING) console.log("watching user here: ", value);
            if (value && value.token) {
                httpClient.setXAuthToken(value.token);
            } else if (value === null || process.env.VUE_ENV === 'server') {
                httpClient.setXAuthToken("");
            }
        }, {immediate: true});
    store.dispatch("user/init");
    return store;
}

export function watchStore(store: Store<any>, getter: (state?: any, getters?: any) => any,
                           callback: (newValue?: any, oldValue?: any, unwatch?: () => void) => void, options?: any): () => void {
    // callback receives a unwatch function as third parameter
    if (options.immediate) {
        let shouldStop = false;
        const current = getter(store.state, store.getters);
        callback(current, undefined, () => {
            shouldStop = true;
        });
        if (shouldStop) {
            return () => {};
        }
    }
    const unwatch = store.watch(
        getter,
        (newValue: any, oldValue: any) => {
            callback(newValue, oldValue, unwatch);
        },
        Object.assign({}, options, {immediate: false}));
    return unwatch;
}

function wrapper1(store: Store<any>, getter: (state?: any, getters?: any) => any,
                  callback: (newValue: any, oldValue: any) => boolean | void, options?: any): () => void {
    // if the provided callback returns true, stop watching
    if (options.immediate) {
        const current = getter(store.state, store.getters);
        if (callback(current, undefined)) {
            return () => {};
        }
    }
    const unwatch = store.watch(
        getter,
        (newValue: any, oldValue: any) => {
            const shouldStop = callback(newValue, oldValue);
            if (shouldStop) {
                unwatch();
            }
        },
        Object.assign({}, options, {immediate: false}));
    return unwatch;
}
