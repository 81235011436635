"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const constants_1 = require("@/models/constants");
function toRows(level, unit) {
    const unpacked = [];
    if (level.kind === constants_1.LEVEL_KIND_SIMPLE) {
        unpacked.push({
            kind: constants_1.KIND_PREPARE,
            value: level.data.prepare
        });
        const work = {
            kind: constants_1.KIND_WORK,
            value: level.data.work,
        };
        if (unit === 'repetitions') {
            work.step = level.data.step || 1000;
        }
        const rest = {
            kind: constants_1.KIND_REST,
            value: level.data.rest
        };
        for (let repeat = 0; repeat < level.data.repeat; repeat++) {
            if (repeat > 0) {
                unpacked.push(rest);
            }
            unpacked.push(work);
        }
        unpacked.push({
            kind: constants_1.KIND_COOLDOWN,
            value: level.data.cooldown
        });
    }
    else if (level.kind === constants_1.LEVEL_KIND_ADVANCED) {
        for (const set of level.data.sets) {
            const row = {
                kind: set.kind,
                value: set.value
            };
            if (unit === 'repetitions') {
                row.step = set.step || 1000;
            }
            unpacked.push(row);
        }
    }
    return unpacked;
}
exports.toRows = toRows;
function unpackData(levels, unit) {
    if (levels && Object.keys(levels).length > 0) {
        const data = {};
        for (const key of Object.keys(levels)) {
            const level = levels[key];
            data[key] = toRows(level, unit);
        }
        return data;
    }
    else {
        return null;
    }
}
exports.unpackData = unpackData;
function dataToQueryObject(item, auto = false, includes) {
    const query = includes ? Object.assign({}, includes) : {};
    if (auto) {
        query.a = 1;
    }
    else {
        delete query.a;
    }
    if (item) {
        if (item.primary_unit === "repetitions") {
            query.u = "r";
        }
        else {
            delete query.u;
        }
        if (item.row.kind === constants_1.LEVEL_KIND_ADVANCED) {
            // advanced mode
            query.k = "a";
            query.d = item.row.data.sets
                .map((row) => {
                if (row.kind === constants_1.KIND_WORK) {
                    return item.primary_unit === "repetitions" ? row.value + "~" + (row.step / 1000).toFixed(1) : row.value;
                }
                else {
                    return row.value;
                }
            })
                .join("-");
        }
        else {
            // simple mode
            delete query.k;
            const data = item.row.data;
            const work = item.primary_unit === "repetitions" ? data.work + "~" + (data.step / 1000).toFixed(1) : data.work;
            query.d = [data.prepare, data.repeat, work, data.rest, data.cooldown].join("-");
        }
    }
    else {
    }
    return query;
}
exports.dataToQueryObject = dataToQueryObject;
function toQueryString(query) {
    return "?" +
        Object.keys(query)
            .sort()
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(query[k]))
            .join("&");
}
exports.toQueryString = toQueryString;
// export function formatMillisToSeconds(string): string {
//     // Math.round((parseFloat(w[1]) * 1000) / 100) * 100
//
// }
function dataToPlayable(data) {
    // if (data) {
    //     const kind = this.data.levels[0].kind;
    //     const data = cloneDeep(this.data.levels[0].data);
    //     if (kind === LEVEL_KIND_ADVANCED) {
    //         data.sets = data.sets.map((row: any) => {
    //             if (row.step) {
    //                 row.step = row.step.value;
    //             }
    //             return row;
    //         })
    //     }
    //     const transformed = {
    //         "1": {
    //             kind: kind,
    //             data: data
    //         }
    //     };
    //     const unpacked = unpackData(transformed);
    //     const sets = setsForLevel(unpacked, "1");
    //     const rows: any[] = [];
    //     if (Array.isArray(sets)) {
    //         const unpacked = sets.map(set => {
    //             set.title = set.kind;
    //             return set;
    //         });
    //         rows.push(...unpacked);
    //     }
    //     return rows;
    // }
    // return "error";
}
exports.dataToPlayable = dataToPlayable;
