"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KIND_WORK = "WORK";
exports.KIND_PREPARE = "PREPARE";
exports.KIND_REST = "REST";
exports.KIND_COOLDOWN = "COOLDOWN";
exports.ENTRY_KIND_WORKOUT = "WORKOUT";
exports.ENTRY_KIND_EXERCISE = "EXERCISE";
exports.LEVEL_KIND_SIMPLE = "simple";
exports.LEVEL_KIND_ADVANCED = "advanced";
