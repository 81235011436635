import Vue from 'vue'
import axios from 'axios';
import {getStatefulPromise, StatefulPromise} from "../../../server/utils/stateful-promise";

const ENABLE_LOGGING = false;

Vue.mixin({
    beforeCreate() {
        const options = this.$options;
        if (options.httpClient) {
            this.$httpClient = options.httpClient;
        } else if (options.parent && options.parent.$httpClient) {
            this.$httpClient = options.parent.$httpClient;
        }
    }
});

export default function makeHttpClient(baseURL: string) {
    const httpClient = axios.create({
        baseURL: baseURL
    });
    httpClient.xAuthToken = undefined;
    httpClient.getXAuthTokenPromise = undefined;
    httpClient.setXAuthToken = function (token: string) {
        if (ENABLE_LOGGING) console.log("settings XAUTHTOKEN: ", token);
        if (typeof token !== 'string') {
            throw Error("token has to be a string or empty string");
        }
        this.xAuthToken = token;
        if (!this.getXAuthTokenPromise || this.getXAuthTokenPromise.isFulfilled()) {
            this.getXAuthTokenPromise = getStatefulPromise();
        }
        this.getXAuthTokenPromise.resolve(this.xAuthToken);
    };
    httpClient.getXAuthToken = function (): StatefulPromise<string> {
        if (ENABLE_LOGGING) console.log("get XAUTHTOKEN called");
        if (!this.getXAuthTokenPromise) {
            this.getXAuthTokenPromise = getStatefulPromise();
        }
        return this.getXAuthTokenPromise;
    };
    httpClient.interceptors.request.use(async function(config) {
        const xAuthToken = await httpClient.getXAuthToken();
        if (xAuthToken) {
            config.headers['X-Auth-Token'] = httpClient.xAuthToken;
        }
        return config;
    });
    return httpClient;
}
