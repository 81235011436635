"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function toStatefulPromise(promise) {
    if (promise.isResolved) {
        return promise;
    }
    let isResolved = false;
    let isRejected = false;
    const result = promise.then((v) => {
        isResolved = true;
        return v;
    }, (e) => {
        isRejected = true;
        throw e;
    });
    result.isFulfilled = () => {
        return isResolved || isRejected;
    };
    result.isResolved = () => {
        return isResolved;
    };
    result.isRejected = () => {
        return isRejected;
    };
    return result;
}
exports.toStatefulPromise = toStatefulPromise;
