export default function(durationSeconds: number): string {
    // const hours = Math.floor(durationSeconds / 3600);
    // durationSeconds %= 3600;
    const minutes = Math.floor(durationSeconds / 60);
    const seconds = durationSeconds % 60;
    return `${pad(minutes)}:${pad(seconds)}`;
}

function pad(number: number): string {
    return (number < 10 ? '0' : '') + number;
}